<template>
  <header id="header" class="header">
    <div class="top-deposit">
      <div class="container">
        <div class="row">
          <a href="#" style="cursor: pointer" @click.prevent="menuStore.openDepositModal()">
            Click to DEPOSIT -> Pay-bill: 498098
          </a>
        </div>
      </div>
    </div>

    <div class="global-header">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="main-header">
              <div id="mainmenu">
                <a href="#" @click.prevent="showSidebarMenu" aria-label="menu" style="color: var(--color-fg)">
                  <span class="main-menu-icon"><i class="bi bi-list"></i></span>
                </a>
              </div>

              <div class="global-search">
                <router-link :to="{ name: 'search' }" class="search-link">
                  <span class="global-search-icon"><i class="bi bi-search"></i></span>
                  <span class="visually-hidden">Search</span>
                </router-link>
              </div>

              <Logo />

              <div class="account-access" v-if="!isLoggedIn">
                <router-link class="register" style="text-transform: capitalize; font-size: 10px; width: 60px !important;" :to="{ name: 'signup' }" exact-active-class="no-active">
                  REGISTER
                </router-link>
                <router-link class="login" style="text-transform: capitalize; font-weight: 800; font-size: 10px; width: 50px !important;" :to="{ name: 'login' }" exact-active-class="no-active">
                  LOGIN
                </router-link>
              </div>
              <div class="logged-in-account" v-else>
                <a href="#" @click.prevent="menuStore.openDepositModal()">
                  <span class="account-balance-wrap">
                    <span class="currency">KSH</span>
                    <span class="account-balance" id="accBal">{{ balance }}</span>
                    <!--<span v-if="bonus !== null" class="account-bonus" id="accBonus">+{{ bonus }}</span>-->
                  </span>
                  <span class="deposit-icon"><i class="bi bi-plus-circle-fill"></i></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DepositModal v-if="menuStore.showDepositModal" @close="menuStore.closeDepositModal()" />
  </header>
</template>

<script>
import Logo from './Logo.vue'
import { useBalanceStore } from '@/stores/balance.js'
import { useAuthStore } from '@/stores/auth.js'
import { useMenuStore } from '@/stores/menu.js'
import { defineAsyncComponent, watch } from 'vue'

export default {
  name: 'Header',
  components: {
    DepositModal: defineAsyncComponent(() => import('@/components/Modals/Depositmodal.vue')),
    Logo
  },
  data() {
    return {
      menuStore: useMenuStore()
    }
  },
  computed: {
    isLoggedIn() {
      const authStore = useAuthStore();
      return authStore.user !== null;
    },
    balance() {
      const balanceStore = useBalanceStore();
      return balanceStore.balance;
    },
    bonus() {
      const balanceStore = useBalanceStore();
      return balanceStore.bonus;
    }
  },
  async mounted() {
    const authStore = useAuthStore();
    const profile = authStore.user;

    if (profile) {
      await useBalanceStore().fetchBalance();
    }

    watch(() => this.isLoggedIn, async (newValue) => {
      if (newValue) {
        await useBalanceStore().fetchBalance();
      }
    });
  },
  methods: {
    showSidebarMenu() {
      const menuStore = useMenuStore();
      menuStore.openSideMenu();
    }
  }
}
</script>

<style scoped>
.modal-header button {
  background-color: var(--color-bg);
  color: var(--color-fg);
  border: none;
}
@media (max-width: 392px) {
  h5 {
    font-size: 12px;
  }
}
.active-btn {
  background-color: rgb(150, 178, 72);
  border: 1px solid rgb(150, 178, 72);
  color: #ffffff;
}
</style>