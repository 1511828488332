<template>
  <div class="main-logo" @click="reloadBasePage">
    <img :src="logoSrc" alt="Maybets Logo" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false,
    };
  },
  computed: {
    logoSrc() {
      return this.isDarkMode
        ? '/assets/images/maybetslight.webp'
        : '/assets/images/backup/maybetslogo.png';
    },
  },
  mounted() {
    this.isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark';
    const observer = new MutationObserver(() => {
      this.isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark';
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });
  },
  methods: {
    reloadBasePage() {
      window.location.href = '/';
    }
  }
};
</script>